html {
  scroll-behavior: smooth;
}

body{
  &.lock{
    overflow: hidden;
  }
  svg{
    max-width: 100%;
    height: auto;
    width: auto;
  }
  .container{
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
    &.header{
      border-bottom: 1px solid rgba(227, 227, 227, 0.25);
    }
  }
  img{
    max-width: 100%;
    width: 100%;
  }
  
  .btn{
    font-family: "Josefin Sans",sans-serif;
    font-style: normal;
    font-weight: 700;
    border-radius: 27.5px;
    box-sizing: border-box;
    display: inline-block;
    padding: 15px 30px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 2px;
    color: #f5b573;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #f5b573;
    &:hover{
      color: white;
      background-color: #f5b573;
    }
  }
  #breadcrumbs{
    margin-top: 30px;
    font-size: 12px;
    @media (min-width: 500px) {
      font-size: 14px;
      margin-top: 100px;
    }
    @media (min-width: 1024px) {
      margin-top: 150px;
    }
    a{
      font-size: 12px;
      @media (min-width: 500px) {
        font-size: 14px;
      }
    }
  }
}
