.benefit__section {
  .myBenefits {
    display: none;
  }
  .benefits__container{
    position: relative;
  }
  .numbers{
    position: absolute;
    display: flex;
    gap: 10px;
    top: 7%;
    left: 0;
    right: 0;
    justify-content: center;
    @media (min-width: 500px) {
      top: 5%;
      gap: 20px;
    }
    @media (min-width: 1024px) {
      justify-content: start;
      top: 10%;
      left: 52.5%;
    }
    @media (min-width: 1200px) {
      top: 16%;
      left: 52%;
    }
    @media (min-width: 1440px) {
      top: 16%;
      left: 52%;
    }
  }
  .number {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    color: #a75b68;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    transition: color 0.6s ease;
  }

  .active, .number:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a75b68;
    border-radius: 100%;
    position: relative;
    width: 30px;
    height: 30px;
    color: white;
    cursor: pointer;
  }

  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  .benefit__grid {
    display: grid;
    align-items: center;
    justify-content: center;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  img{
    height: auto;
    order: 2;
    @media (min-width: 1024px) {
      order: 1;
    }
  }
  .benefit__text {
    position: relative;
    padding: 100px 20px 30px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    order: 1;
    @media (min-width: 1024px) {
      padding-left: 30px;
      margin: 0;
      text-align: left;
      order: 2;
    }
  }  
}
