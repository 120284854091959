.about__section {
  background: #fafafa;
  border-top: 1px solid #e3e3e3;
  .about__grid {
    display: grid;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .about__text {
    padding: 30px;
    text-align: center;
    @media (min-width: 500px) {
      padding: 60px;
    }
    @media (min-width: 768px) {
      text-align: left;
      padding: 30px 60px;
    }
  }
  img{
    width: 100%;
    height: auto;
  }
  .btn {
    margin-top: 30px;
  }
}