.hero__section{
  .slideshow-container {
    position: relative;
    margin: auto;
    height: 100%;
  }
  .slider-content {
    position: relative;
    height: 92vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top 0 right 40%;
    @media (min-width: 500px) {
      background-position: center;
      height: 100vh;
    }
  }
  .slider-content::after{
    content: "";
    position: absolute;
    background: #000;
    opacity: 0.4;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  p{
    color: white;
  }
  img{
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .slider__text{
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    z-index: 4;
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  .dots{
    text-align: center;
    position: absolute;
    display: inline-block;
    bottom: 2%;
    z-index: 5;
    left: 0;
    right: 0;
  }
  .dot{
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 2px;
    border: 1px solid white;
    border-radius: 50%;
    display: inline-block;
    transition: color 0.6s ease;
  }

  .active, .dot:hover {
    background-color: white;
  }

  .fade {
    animation-name: fade;
    animation-duration: 2.5s;
  }

  @keyframes fade {
    from {opacity: .6} 
    to {opacity: 1}
  }
}

