header{
  z-index: 5;
  @media (min-width: 500px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 5px 0;
  }
  &.black{
    .navigation{
      display: none;
      @media (min-width: 500px) {
        display: block;
        a{
          color: #191919;
        }
      }
    }
    .header__basket-desctop{
      svg{
        fill: #191919;
      }
    }  
    .nav_link.active_color{
      color: #f5b573;
    }
  }
  .header__grid {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    justify-content: space-around;
    align-items: center;
    @media (min-width: 500px) {
      justify-items: end;
      align-items: center;
      grid-template-columns: 120px 1fr 90px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: 200px 1fr 90px;
    }
  }
  .header__logo {
    text-align: center;
    z-index: 20;
    &::before{
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      overflow: hidden;
      top: 59px;
      left: 0;
      background: rgba(227, 227, 227, 0.25);
    }
    img{
      width: 120px;
      @media (min-width: 768px) {
        width: 200px;
      }
    }
    @media (min-width: 500px) {
      text-align: left;
      &::before{
        display: none;
      }
    }
  }
  .navigation{
    display: none;
    @media (min-width: 500px) {
      display: block;
      a{
        color: white;
      }
    }
  }
  .nav_link {
    position: relative;
    font-size: 12px;
    padding: 20px 15px;
    &:hover{
      color: #f5b573;
      transition: all 0.5s ease;
    }
  }
  .nav_link.active_color{
    color: #f5b573;
  }
  .header__basket-desctop{
    display: none;
    @media (min-width: 500px) {
      display: block;
      a{
        display: inline-block;
        margin: 8px 0 0;
        padding: 0;
        &:hover{
          color: #f5b573;
        }
      }
      svg{
        width: 18px;
        height: 18px;
        fill: white;
        &:hover{
          fill: #f5b573;
        }
      }
      &::before{
        content: "";
        position: absolute;
        transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        display: inline-block;
        width: 1px;
        height: 16px;
        margin: 10px -40px;
        vertical-align: middle;
        background-color: #e3e3e3;
      }
    }
  }
  .header__basket-mobile{
    display: block;
    z-index: 20;
    a{
      padding: 0;
      &:hover{
        color: #f5b573;
      }
    }
    svg{
      width: 18px;
      height: 18px;
      fill: #191919;
      &:hover{
        fill: #f5b573;
      }
    }
    .basket.active{
      svg{
        fill: white;
        &:hover{
          fill: #f5b573;
        }
      }
    }
    @media (min-width: 500px) {
      display: none;
    }
  }
  .navigation__mobile{
    display: block;
    @media (min-width: 500px) {
      display: none;
    }
  }
  .nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    background-color: #191919fa;
    padding: 100px 30px 30px 30px;
    transition: left 0.3s ease 0s;
    a:hover{
      border-bottom: 1px solid #f5b573;
      transition: all 1s ease-in-out;
    }
  }  
  .nav a{
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    width: 23%;
    margin: 0 auto;
    font-size: 22px;
  }
  .nav.active{
    z-index: 10;
    transform: translateY(0%);
  }
  .burger {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 20;
    overflow: hidden;
    @media (min-width: 500px) {
      display: none;
    }
  }

  .burger div{
    width: 20px;
    height: 1px;
    background: #191919;
    margin: 5px;
    transition: all 0.3s ease;
  }    
  .burger.line1,.burger.line3{
    transform: rotate(0deg) translate(0 );
  }
  .burger .line2{
    opacity: 1;
  }
  .burger.active .line2 {
    opacity: 0;
  }
  .burger.active .line1 {
    background-color: #f5b573;
    transform: rotate(-45deg) translate(-5px,6px );
  }
  .burger.active .line3 {
    background-color: #f5b573;
    top: 50%;
    transform: rotate(45deg) translate(-2px,-4px );
  } 
}
