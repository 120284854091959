.testimonials__section {
  padding: 50px 20px;
  @media (min-width: 500px) {
    padding: 90px 10px;    
  }
  h2{
    text-align: center;
    margin-bottom: 45px;
  }
  p{
    text-align: center;
    i{
      font-style: italic;
    }
  }
  .swiper-wrapper{
    height: auto;
    margin: 0 auto;
  }
  .testimonial {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding-bottom: 50px;
    overflow: hidden;
    @media (min-width: 500px) {
      padding-bottom: 100px;
    }
  }
  .testimonial .image {
    height: 170px;
    width: 170px;
    object-fit: cover;
    border-radius: 50%;
  }
  .testimonial .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
    height: 100%;
    width: 100%;
  }
  .testimonial .person__info {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    @media (min-width: 500px) {
    }
    img{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      @media (min-width: 500px) {
        width: 60px;
        height: 60px;
      }
    }
    .name {
      text-align: left;
      font-family: "Oswald",sans-serif;
      font-style: normal;
      letter-spacing: 1px;
      color: #a75b68;
    }
  }
  .slide .swiper-slide .swiper-slide-active{
    width: 370px;
  }
  .swiper-pagination-bullet {
    margin-top: 80px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #797676;
    background-color: white;
    border-radius: 100%;
  }
  .swiper-pagination-bullet:hover, .swiper-pagination-bullet-active {
    border: 1px solid #a75b68;
    background-color: #a75b68;
  }
  .swiper-button-next {
    display: none;
    @media (min-width: 768px) {
      display: block;
      font-size: 18px;
      right: 3%;
      left: auto;
      color:#f5b573;
    }
    @media (min-width: 1200px) {
      right: 31%;
    }
  }
  .swiper-button-prev{
    display: none;
    @media (min-width: 768px) {
      font-size: 18px;
      display: block;
      left: 3%;
      right: auto;
      color:#f5b573;
    }
    @media (min-width: 1200px) {
      left: 31%;
    }
  }
  .swiper-button-prev::after, .swiper-button-next::after{
    font-size: 30px;

  }
  .swiper-button-prev:hover::after, .swiper-button-next:hover::after{
    color: #797676;
  }
}
  

