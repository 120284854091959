.product__section {
  background: #fafafa;
  text-align: center;
  padding: 50px 0;
  &.bg{
    background-color: white;
  }
  .container{
    max-width: 1245px;
  }
  h2{
    margin-bottom: 50px;
  }
  .product__grid {
    display: flex;
    align-content: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  img{
    height: auto;
    @media (min-width: 500px) {
      width: 384px;
    }
  }
  .product__card {
    background-color: white;
    align-content: stretch;
    text-align: center;

  }
  .product__text{
    padding: 30px 15px;
    h3{
      color: #1f1f1f;
    }
    .cost {
      &.red{
        color: #a75b68;
        font-weight: 700;
      }
    } 
  }
}
