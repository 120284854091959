.footer {
  color: #fff;
  background-color: #191919;
  .footer__grid {
    padding: 30px 0 60px;
    @media (min-width: 500px) {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  .footer__logo {
    img{
      width: 200px;
      height: auto;
    }
  }
  .footer__socials {
    h5{
      font-size: 12px;
    }
    a{
      font-size: 14px;
    }
    h5,a{
      color: white;
    }
  }
  .footer__copyright {
    p{
      font-size: 14px;
      color: white;
    }
  }
  .footer__payment {
    padding-bottom: 35px;
    text-align: center;
    @media (min-width: 500px) {
      text-align: left;
    }
    svg{
      padding: 0 4px;
    }
  }
}
