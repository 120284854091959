.page__content{
  .page__grid {
    display: grid;
    gap: 30px;
    padding: 40px 0 50px;
    @media (min-width: 500px) {
      padding: 60px 0 90px;
    }
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  img{
    height: auto;
  }
  h2,.cost{
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
  .product__info{
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
  .page__content {
    h3{
      margin-top: 50px;
      color: #1f1f1f;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #f5b573;
    }
    .btn {
      padding: 14px 60px;
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }
  .quantity-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 121px;
    margin: 0 auto 25px;
    border: 1px solid #e3e3e3;
    @media (min-width: 768px) {
      margin: 0 0 25px;
    }
    input{
      width: 50px;
      font-size: 14px;
    }
    .text{
      text-align: center;
    }
    .increment,
    .decrement {
      width: 30px;
      border: 0;
      background: transparent;
      cursor: pointer;
      background: #e2e2e2;
      color: #797676;
      height: 30px;
    }
  }

  .social-media {
    .social-media__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    a{
      border: 1px solid #e3e3e3;
    }
    svg{
      width: 22px;
      height: 22px;
      @media (min-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
    .facebook{
      fill: #4460A0;
      &:hover{
        background-color: #4460A0;
        fill: white;
      }
    }
    .twitter{
      fill: #00AAEC;
      &:hover{
        background-color: #00AAEC;
        fill: white;
      }
    }
    .pinterest{
      fill: #CC2127;
      &:hover{
        background-color: #CC2127;
        fill: white;
      }
    }
    .fancy{
      fill:#46A5E3;
      &:hover{
        background-color: #46A5E3;
        fill: white;
      }

    }
  }
}