.difference__section {
  background: #fafafa;
  border-top: 1px solid #e3e3e3;
  padding: 50px 0;
  @media (min-width: 500px) {
    padding: 90px 0;
  }
  h2,p{
    text-align: center;
  }
  .difference__grid {
    display: grid;
    align-items: center;
    @media (min-width: 768px) {
      gap: 30px;
      grid-template-columns: 1fr 300px 1fr;
    }
  }
  img{
    width: 80%;
    height: auto;
    order: 1;
    margin: 20px auto;
    @media (min-width: 425px){
      width: 50%;
    }
    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 100%;
      order: 2;
    }
  }
  .difference__text-first {
    text-align: center;
    direction: rtl;
    order: 2;
    p{
      text-align: center;
    }
    @media (min-width: 768px) {
      order: 1;
      text-align: right;
      direction: ltr;
      p{
        text-align: right;
      }
    }
  }
  .difference__text-top, .difference__text-bottom {
    margin: 10px auto;
    max-width: 450px;
    @media (min-width: 768px) {
      margin: 30px 0;
    }
  }
  .difference__text-second {
    order: 3;
    text-align: center;
    p{
      text-align: center;
    }
    @media (min-width: 768px) {
      text-align: left;
      p{
        text-align: left;
      }
    }
  }
}

