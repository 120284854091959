.video__section {
  border-top: 1px solid #e3e3e3;
  padding-top: 50px;
  @media (min-width: 500px) {
    padding-top: 90px;
  }
  h2,p{
    text-align: center;
  }
  p{
    padding-bottom: 30px;
  }
  .ytp-large-play-button{
    transform: translateY(-50%);
    left: 50%;
  }
  .videowrapper {
    float: none;
    clear: both;
    width: 80%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    margin: 0 auto 100px auto;

    iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(100vw * 0.5);
    }
  }
}
