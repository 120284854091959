.history__section {
  border-top: 1px solid #e3e3e3;
  .history__grid {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .history__text {
    text-align: center;
    padding: 20px 20px 70px;
    order: 2;
    @media (min-width: 500px){
      padding: 30px 60px;
    }
    @media (min-width: 1200px) {
      text-align: left;
      order: 1;
    }
  }
  img{
    width: 100%;
    margin: 0 auto;
    height: auto;
    order: 1;
    @media (min-width: 500px) {
      width: 80%;
    }
    @media (min-width: 1200px) {
      width: 100%;
      order: 2;
    }
  }
  .btn {
    margin-top: 30px;
  }
}
