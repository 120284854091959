/* Oswald - regular */
@font-face {
  font-family: 'Oswald',sans-serif;
  src: url(/assets/fonts/Oswald/oswald.regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/* Open Sans - regular */
@font-face {
  font-family: 'Open Sans',sans-serif;
  src: url(/assets/fonts/Open-Sans/OpenSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Open Sans - italic */
@font-face {
  font-family: 'Open Sans',sans-serif;
  src: url(/assets/fonts/Open-Sans/OpenSans-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Open Sans - bold */
@font-face {
  font-family: 'Open Sans',sans-serif;
  src: url(/assets/fonts/Open-Sans/OpenSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Josefin Sans - regular */
@font-face {
  font-family: 'Open Sans',sans-serif;
  src: url(/assets/fonts/Josefin-Sans/JosefinSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


* {
  font-family: "Open Sans",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625;
  color: #797676;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Oswald',sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: inherit;
}
b{
  font-weight: 700;
}

p, ul, ol, a{
  font-family: "Open Sans",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.625;
  color: #797676;
  padding: 5px 0;
  @media (min-width: 500px) {
    font-size: 16px;
    padding: 15px 0;
  }
}
a{
  color: initial;
}

h1{
  font-size: 36px;
  line-height: 42px;
  color: white;
  padding-bottom: 20px;
  @media (min-width: 500px) {
    font-size: 48px;
    line-height: 58px;
  }
  &.page{
    color: #1f1f1f;
    text-align: center;
    padding-top: 50px;
  }
}

h2{
  font-size: 28px;
  line-height: 32px;
  color: #1f1f1f;
  margin-bottom: 10px;
  @media (min-width: 500px) {
    font-size: 36px;
    line-height: 46px;
  }
}

h3{
  margin: 0 0 10px;
  font-size: 22px;
  line-height: 32px;
  color: #a75b68;
}
